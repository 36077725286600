import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import './ColdStoreLotNumbers.scss';
import { HStack, IconButton, Input, VStack } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import { AddIcon } from '@chakra-ui/icons';

class ColdStoreLotNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lotNumbers: [],
    };
  }

  static propTypes = {
    lotNumbers: PropTypes.arrayOf(PropTypes.string),
    onObjectValueChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  componentDidUpdate() {
    // set a default value
    const { lotNumbers } = this.props;
    if (!this.state.lotNumbers.length) {
      if (lotNumbers && lotNumbers.length) {
        this.setState({ lotNumbers: cloneDeep(lotNumbers) });
      } else {
        this.setState({ lotNumbers: [''] });
      }
    }
  }

  updateLotNumbers = (index, lotNumber) => {
    const { lotNumbers } = this.state;
    const copyLotNumbers = cloneDeep(lotNumbers);
    if (lotNumber !== null || lotNumber !== '') {
      copyLotNumbers[index] = lotNumber;
    } else {
      copyLotNumbers.splice(index, 1);
    }
    this.setState({ lotNumbers: copyLotNumbers });
    this.props.onObjectValueChange('coldstoreDetails', 'lotNumbers', copyLotNumbers);
  };

  addLotNumberField = () => {
    const { lotNumbers } = this.state;
    const copy = cloneDeep(lotNumbers);
    copy.push('');
    this.setState({ lotNumbers: copy });
  };

  deleteLotNumberField = indx => {
    const { lotNumbers } = this.state;
    const copy = cloneDeep(lotNumbers);
    copy.splice(indx, 1);
    this.setState({ lotNumbers: copy });
  };

  render() {
    const { disabled } = this.props;
    return (
      <VStack spacing="21px">
        {this.state.lotNumbers.map((lotNumber, indx) => {
          return (
            <HStack spacing="10px">
              <CustomFormControl width="257px" key={indx}>
                <Input
                  backgroundColor="white"
                  key={indx}
                  type="text"
                  name="lot-number"
                  value={lotNumber || ''}
                  isDisabled={disabled}
                  className="form-control"
                  onChange={e => this.updateLotNumbers(indx, e.target.value)}
                />
              </CustomFormControl>
              {indx > 0 ? (
                <IconButton
                  variant="ghost"
                  type="delete"
                  hidden={disabled}
                  onClick={() => this.deleteLotNumberField(indx)}
                  icon={<TrashOutlineIconSVG />}
                  height="25px"
                  width="25px"
                  fontSize="25px"
                  marginLeft="0px !important"
                  paddingLeft="12px"
                />
              ) : (
                <IconButton
                  colorScheme="actionSecondary"
                  variant="solid"
                  hidden={disabled}
                  onClick={() => this.addLotNumberField()}
                  icon={<AddIcon />}
                  height="25px"
                  minWidth="25px"
                  borderRadius="14px"
                />
              )}
            </HStack>
          );
        })}
      </VStack>
    );
  }
}

export default ColdStoreLotNumbers;
