import React, { useState, useEffect, useCallback } from 'react';
import {
  HStack,
  Select,
  InputGroup,
  Input,
  IconButton,
  Flex,
  InputLeftElement,
  VStack,
  Heading,
  Stack,
  Skeleton,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { SearchIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { RiPencilFill } from 'react-icons/ri';
import { FaUserSlash, FaUserShield } from 'react-icons/fa';
import { debounce } from 'lodash';
import { PaginationBar } from '../../core/PaginationComponents';
import NoData from '../_components/NoData';
import ContactBedge from './ContactBedge';
import RightAngleIcon from '../../core/RightAngleIcon';
import Tooltip from '../_components/Tooltip';
import { getStatusColor } from '../../../helpers';
import AddContact from './AddContact';
import { actions, fetchContacts } from '../../../reducers/reducer_food_safety_quality';
import Filters from '../_components/Filters';
import api from '../../../api';
import DeleteContact from './DeleteContact';

const columns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    render: name => <ContactBedge name={name} />,
  },
  {
    title: 'CONTACT OF',
    dataIndex: 'contact_of',
    render: (name, record) => `${record.associated_to ?? ''} ${record.associated_to ? `(${name})` : name}`,
  },
  {
    title: 'ROLE',
    dataIndex: 'role',
    sorter: (a, b) => {
      const resourceA = a.role.toUpperCase();
      const resourceB = b.role.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'CONTACT TYPE',
    dataIndex: 'contact_type',
    sorter: (a, b) => {
      const resourceA = a.contact_type.toUpperCase();
      const resourceB = b.contact_type.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'CONTACT NUMBER',
    dataIndex: 'mobile',
    render: (mobile, record) => `${record.country_code} ${mobile}`,
    sorter: (a, b) => {
      const resourceA = a.mobile.toUpperCase();
      const resourceB = b.mobile.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'EMAIL ADDRESS',
    dataIndex: 'email',
    render: email => (
      <Link
        _hover={{ color: 'inherit', textDecoration: 'underline' }}
        _active={{ color: 'inherit', textDecoration: 'underline' }}
        textDecoration="underline"
        href={`mailto:${email}`}
      >
        {email}
      </Link>
    ),
    sorter: (a, b) => {
      const resourceA = a.email.toUpperCase();
      const resourceB = b.email.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    render: status => (
      <Text
        key={status}
        as="span"
        fontSize="11px"
        fontWeight="500"
        letterSpacing="0.4px"
        padding="4px 12px"
        borderRadius="6px"
        background={getStatusColor(status).bgColor}
        color={getStatusColor(status).color}
        textTransform="capitalize"
      >
        {status}
      </Text>
    ),
  },
  {
    title: 'ACTION',
    dataIndex: 'actions',
  },
];

const filtersList = [
  { label: 'All', value: {} },
  { label: 'Alphabetically (A-Z)', value: { sortby: 'alphabetic' } },
  { label: 'Recently Added', value: { sortby: 'recent' } },
  { label: 'Active', value: { status: 'Active' } },
  { label: 'Deactivated', value: { status: 'Deactivated' } },
  { label: 'FMG Users', value: { contact_of: 'FMG' } },
  { label: 'Grinders', value: { contact_of: 'Grinder' } },
  { label: 'Sponsor', value: { contact_of: 'Sponsor' } },
];

/**
 * Renders the ImportantContacts component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpenAddPopup - Flag indicating whether the edit popup is open.
 * @param {function} props.setIsOpenAddPopup - Function to set the state of isOpenAddPopup.
 * @returns {JSX.Element} The ImportantContacts component.
 */
const ImportantContacts = ({ isOpenAddPopup, setIsOpenAddPopup }) => {
  const dispatch = useDispatch();
  const { deleteContact } = actions;
  const { contacts, loading } = useSelector(state => ({
    contacts: state.food_safety_quality.contacts,
    loading: state.food_safety_quality.loading,
  }));
  const [editItem, setEditItem] = useState(null);
  const [filters, setFilters] = useState({});
  const toast = useToast();

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    currentPage: 1,
    query: '',
  });

  const onEditHandler = _item => {
    setEditItem(_item);
    setIsOpenAddPopup(true);
  };

  const handleItemsPerPageSelect = e => {
    const currentPageValue = 1;
    const itemsPerPageValue = parseInt(e.target.value, 10);

    setPaginationState({
      ...paginationState,
      itemsPerPage: itemsPerPageValue,
      currentPage: currentPageValue,
    });
  };

  const handlePageClick = selected => {
    setPaginationState({ ...paginationState, currentPage: selected });
  };

  const deleteContactHandler = async id => {
    try {
      const response = await api.delete(`/op-importantcontact/${id}`);
      if (response.status === 204) {
        dispatch(deleteContact(id));
        toast({
          title: 'Contact deleted successfully',
          position: 'top-right',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(`Failed to delete contact. ${response.error}`);
      }
    } catch (error) {
      toast({
        title: error.message,
        position: 'top-right',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateContactStatus = async (id, status) => {
    try {
      const response = await api.patch(`/deactivate-importantcontact/${id}`, { status });
      if (response.ok) {
        const json = await response.json();
        dispatch(actions.updateContact(json));
        toast({
          title: 'Contact status updated successfully',
          position: 'top-right',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(`Failed to update contact status. ${response.error}`);
      }
    } catch (error) {
      toast({
        title: error.message,
        position: 'top-right',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSearch = e => {
    const { value: searchInputValue } = e.target;

    setPaginationState(prevState => ({
      ...prevState,
      currentPage: 1,
      query: searchInputValue,
    }));
  };

  const fetchContactsHandler = useCallback(
    debounce(pState => dispatch(fetchContacts(pState)), 300),
    []
  );

  useEffect(() => {
    fetchContactsHandler({ ...paginationState, ...filters });
  }, [paginationState.currentPage, paginationState.itemsPerPage, paginationState.query, filters]);

  useEffect(() => {
    if (!isOpenAddPopup) {
      setEditItem(null);
    }
  }, [isOpenAddPopup]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" width="100%" marginBlock="20px">
        <HStack spacing="10px">
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Important Contacts
          </Heading>
        </HStack>

        <Flex alignItems="center">
          <Filters
            value={filters}
            onChange={value => {
              setFilters(value);
              setPaginationState(prevState => ({
                ...prevState,
                currentPage: 1,
              }));
            }}
            options={filtersList}
          />
          <InputGroup marginTop="5px !important" width="264px">
            <InputLeftElement>
              <SearchIcon color="#0000005E" />
            </InputLeftElement>
            <Input
              marginLeft="auto !important"
              placeholder="Search"
              width="100%"
              height="40px"
              fontWeight="normal !important"
              value={paginationState.query}
              backgroundColor="#ffffff"
              onChange={handleSearch}
            />
          </InputGroup>
        </Flex>
      </Flex>
      <VStack
        align="stretch"
        spacing="20px"
        padding="30px"
        boxShadow="md"
        borderRadius="6px"
        backgroundColor="card.default"
        width="100%"
        minHeight="calc(100vh - 320px)"
      >
        {loading && (
          <Stack>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
              <Skeleton key={i} height="25px" />
            ))}
          </Stack>
        )}

        {!loading && contacts?.items?.length ? (
          <>
            <Table
              pagination={{
                pageSize: paginationState.itemsPerPage,
                current: paginationState.currentPage,
                style: { display: 'none' },
              }}
              rowClassName="food-safety-table-row"
              className="food-safety-table"
              dataSource={contacts?.items?.map(itm => ({
                ...itm,
                actions: (
                  <Flex gap={3} css={{ gap: '10px' }}>
                    <Tooltip content="Edit">
                      <IconButton
                        width="24px"
                        height="24px"
                        padding="0"
                        minW="auto"
                        border="1px solid #D9DADF"
                        borderRadius="2px"
                        color="#00000099"
                        backgroundColor="#fff"
                        icon={<RiPencilFill size={12} />}
                        onClick={() => onEditHandler(itm)}
                      />
                    </Tooltip>
                    <Tooltip content={itm.status === 'Active' ? 'Deactivate' : 'Active'}>
                      <IconButton
                        width="24px"
                        height="24px"
                        padding="0"
                        minW="auto"
                        border="1px solid #D9DADF"
                        borderRadius="2px"
                        color="#00000099"
                        backgroundColor="#fff"
                        icon={itm.status === 'Active' ? <FaUserSlash size={12} /> : <FaUserShield size={12} />}
                        onClick={() => updateContactStatus(itm.id, itm.status === 'Active' ? 'Deactivated' : 'Active')}
                      />
                    </Tooltip>
                    <DeleteContact onDelete={() => deleteContactHandler(itm.id)} />
                  </Flex>
                ),
              }))}
              columns={columns}
            />
            <Flex marginTop="auto !important">
              <HStack marginLeft="auto" minW="100px">
                <Select
                  width="fit-content"
                  marginLeft="auto"
                  onChange={e => handleItemsPerPageSelect(e)}
                  value={paginationState.itemsPerPage}
                >
                  {[10, 20, 30, 40, 50].map(item => (
                    <option key={item} value={item}>
                      Show {item}
                    </option>
                  ))}
                </Select>
              </HStack>
              <PaginationBar
                currentPage={paginationState.currentPage}
                pages={contacts?.pages}
                onPageChange={page => handlePageClick(page)}
                justifyContent="flex-end"
              />
            </Flex>
          </>
        ) : (
          !loading && <NoData />
        )}
        {isOpenAddPopup && (
          <AddContact editItem={editItem} isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
        )}
      </VStack>
    </>
  );
};

ImportantContacts.propTypes = {
  isOpenAddPopup: PropTypes.bool,
  setIsOpenAddPopup: PropTypes.func,
};

export default ImportantContacts;
