import { Box, Button, FormControl, FormLabel, HStack, Input, Stack, Heading, IconButton } from '@chakra-ui/react';
import { Alert } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import RightAngleIcon from '../../../components/core/RightAngleIcon';
import './documents-queue-tester.scss';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

export default () => {
  const { token } = useSelector(selectCurrentUser);
  const [axiosConfig, setAxiosConfig] = useState();
  const [testResult, setTestResult] = useState();
  const [isLoading, setLoading] = useState();

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}',
    });
  }, [token]);

  const testEmailAttachment = async emailAttachment => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append('email_attachment', JSON.stringify(emailAttachment));
      if (emailAttachment.file) {
        data.append('file', emailAttachment.file, emailAttachment.file.filename);
      }
      const res = await axios.post(`${Constants.URL}emails/attachments-test`, data, axiosConfig);
      setTestResult(res.data);
    } finally {
      setLoading(false);
    }
  };

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      email: {
        subject: '',
        text: '',
        from_email: { value: [{ address: '', name: '' }] },
        to: { value: [{ address: '', name: '' }] },
      },
    },
    validationSchema: Yup.object().shape({
      email: Yup.object().shape({
        subject: Yup.string().nullable(),
        text: Yup.string().nullable(),
        from_email: Yup.object().shape({
          value: Yup.array().of(
            Yup.object().shape({
              address: Yup.string(),
              name: Yup.string(),
            })
          ),
        }),
        to: Yup.object().shape({
          value: Yup.array().of(
            Yup.object().shape({
              address: Yup.string(),
              name: Yup.string(),
            })
          ),
        }),
      }),
      file: Yup.mixed().nullable(),
    }),
    onSubmit: testEmailAttachment,
  });

  return (
    <Box className="documents-tester" margin="2em">
      <HStack spacing="10px" justifyContent="space-between" mb={6}>
        <HStack>
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Tester
          </Heading>
        </HStack>
      </HStack>
      <HStack align="start">
        <form onSubmit={handleSubmit} className="tester-form">
          <Stack spacing="10">
            <FormControl id="email.subject" isInvalid={errors['email.subject'] && touched['email.subject']}>
              <FormLabel>
                Email subject&nbsp;
                <Tooltip content="Enter the subject line of the email to test document upload" placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                name="email.subject"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.subject']}
              />
              {errors['email.subject'] && touched['email.subject'] && (
                <Alert style={{ marginTop: '5px' }} message={errors['email.subject']} type="error" />
              )}
            </FormControl>
            <FormControl id="email.text" isInvalid={errors['email.text'] && touched['email.text']}>
              <FormLabel>
                Email Body&nbsp;
                <Tooltip content="Provide the content of the email for testing" placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                name="email.text"
                type="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.text']}
              />
              {errors['email.text'] && touched['email.text'] && (
                <Alert style={{ marginTop: '5px' }} message={errors['email.text']} type="error" />
              )}
            </FormControl>
            <FormControl
              id="email.from_email.value[0].address"
              isInvalid={errors['email.from_email.value[0].address'] && touched['email.from_email.value[0].address']}
            >
              <FormLabel>
                Email Address From&nbsp;
                <Tooltip content="Specify the email address from which the test email is sent" placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                name="email.from_email.value[0].address"
                type="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.from_email.value[0].address']}
              />
              {errors['email.from_email.value[0].address'] && touched['email.from_email.value[0].address'] && (
                <Alert
                  style={{ marginTop: '5px' }}
                  message={errors['email.from_email.value[0].address']}
                  type="error"
                />
              )}
            </FormControl>
            <FormControl
              id="email.to.value[0].address"
              isInvalid={errors['email.to.value[0].address'] && touched['email.to.value[0].address']}
            >
              <FormLabel>
                Email Address To&nbsp;
                <Tooltip content="Specify the email address to which the test email is sent" placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                name="email.to.value[0].address"
                type="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.to.value[0].address']}
              />
              {errors['email.to.value[0].address'] && touched['email.to.value[0].address'] && (
                <Alert style={{ marginTop: '5px' }} message={errors['email.to.value[0].address']} type="error" />
              )}
            </FormControl>
            <FormControl id="file" isInvalid={errors.file && touched.file}>
              <FormLabel>
                Email Attachment&nbsp;
                <Tooltip content="Attach a document to the email for testing the upload process" placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                name="file"
                type="file"
                onChange={e => {
                  console.log('e.target.files', e.target.files[0]);
                  setFieldValue('file', e.target.files[0]);
                }}
                onBlur={handleBlur}
              />
              {errors.file && touched.file && <Alert style={{ marginTop: '5px' }} message={errors.file} type="error" />}
            </FormControl>
          </Stack>
          <HStack justifyContent="end">
            <Button type="submit" colorScheme="actionPrimary" mt="3em" isDisabled={!isValid} isLoading={isLoading}>
              Test
            </Button>
          </HStack>
        </form>
        <Box className="tester-results">
          {testResult ? (
            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: testResult }}></div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <br />
                <b>No Test Results Available!</b>
              </div>
              <div style={{ textAlign: 'center' }}>Please provide the necessary information to view your results.</div>
            </div>
          )}
        </Box>
      </HStack>
    </Box>
  );
};
