import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { checkDateErrors, checkErrors, commify, selectValue, validateQuantity, validateFields } from '../../functions';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  VStack,
  Button,
  IconButton,
} from '@chakra-ui/react';
import {
  fetchOrderHistoryByUser,
  fetchOrderPending,
  sendOrder,
  sendRecurringOrder,
} from '../../actions/actions_orders';
import RightAngleIcon from '../core/RightAngleIcon';
import OrderFormFinalizeMessage from './order-form/OrderFormFinalizeMessage';
import ErrorMessage from './order-form/ErrorMessage';
import PurchaseOrderForm from './PurchaseOrderForm';
import Constants from '../../Constants';
import { prepareAmericanOrder, prepareRecurringOrder } from '../lib/PurchaseOrderFunctions';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import IUnleashClassFlagProvider from '../unleash/UnleashClassFlagProvider';
import _ from 'lodash';
import { getDefaultValueFromConfigs } from '../../utils';
import { fetchEndUsers } from '../../actions/actions_end_user';
import { fetchConfigs } from '../../actions/actions_config';
import Tooltip from '../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const defaultFormValues = {
  packerPlantId: '',
  grinderUid: '',
  transporterId: '',
  dropTrailer: '',
  shipmentDate: '',
  deliveryDate: '',
  scheduledDeliveryTime: '',
  productionDate: '',
  transportCostPerUnit: '',
  status: '',
  recurringDateStart: '',
  recurringDateEnd: '',
  ordersPerDayOfWeek: [0, 0, 0, 0, 0, 0, 0],
  truckLoadNo: '',
  productType: Constants.PRODUCT_TYPE_LIST.BEEF,
  lines: [
    {
      inputProductUid: '',
      numberOfBins: 0,
      pricePerUnit: 0,
      buyQuantity: 0,
      sellQuantity: 0,
      expectedProductionDate: null,
      priceType: 'spot',
      formulaName: '',
      formulaMarket: 'USDA National',
      formulaDay: '',
      formulaDaysAveraged: 0,
      formulaBasis: -0.01,
      contractStartDate: '',
      contractEndDate: '',
    },
  ],
  endUserId: '',
};

class PurchaseOrder extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    fastLoaded: PropTypes.bool,
    freight_rate: PropTypes.arrayOf(PropTypes.object),
    historyLoaded: PropTypes.bool,
    input_products: PropTypes.arrayOf(PropTypes.object),
    latestOrders: PropTypes.arrayOf(PropTypes.object),
    load_sizes: PropTypes.arrayOf(PropTypes.object),
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    pendingLoaded: PropTypes.bool,
    poFormPendingLoaded: PropTypes.bool,
    token: PropTypes.string,
    units_of_measure: PropTypes.arrayOf(PropTypes.object),
    user_order_history: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({ id: PropTypes.number }),
    endUsers: PropTypes.arrayOf(PropTypes.object),
    configs: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);
    this.addPurchaseLine = this.addPurchaseLine.bind(this);
    this.removePurchaseLine = this.removePurchaseLine.bind(this);
    this.reset = this.reset.bind(this);
    this.state = {
      isLoading: false,
      error: false,
      dateError: false,
      shipmentDateError: false,
      buyQuantityError: false,
      singleOrderForm: true,
      finalizeSuccessMsg: false,
      pendingExpanded: false,
      orderHistoryExpanded: false,
      copies: 1,
      formValues: defaultFormValues,
    };
  }

  componentDidMount() {
    const { configs, endUsers, dispatch, token } = this.props;
    this.props.dispatch(fetchEntitiesData());
    if (endUsers.length === 0) {
      dispatch(fetchEndUsers(token));
    }
    if (configs.length === 0) {
      dispatch(fetchConfigs(token));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      finalizeSuccessMsg: nextProps.pendingLoaded || nextProps.fastLoaded,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { freight_rate: freight_rate } = this.props;
    const { formValues } = this.state;
    if (
      formValues.grinderUid !== prevState.formValues.grinderUid ||
      formValues.packerPlantId !== prevState.formValues.packerPlantId
    ) {
      let thisTransport = false;
      freight_rate.forEach(fr => {
        if (fr.packer_plant_id === formValues.packerPlantId && fr.grinder_uid === formValues.grinderUid) {
          thisTransport = fr;
        }
      });
      const formValuesCopy = JSON.parse(JSON.stringify(formValues));
      formValuesCopy.transportCostPerUnit = thisTransport ? thisTransport.cost : formValues.transportCostPerUnit;
      this.setState({
        formValues: formValuesCopy,
      });
    }
  }

  changeOrderHistorySectionState = state => {
    const { dispatch, token, user } = this.props;
    if (state) {
      dispatch(fetchOrderHistoryByUser(token, user.id));
    }

    this.setState({ orderHistoryExpanded: state });
  };

  changePendingOrderSectionState = state => {
    if (state) {
      this.props.dispatch(fetchOrderPending(this.props.token));
    }

    this.setState({ pendingExpanded: state });
  };

  handleSelectValue = (type, e, index) => {
    const { formValues } = this.state;
    const formValuesCopy = JSON.parse(JSON.stringify(formValues));
    const newFormValues = selectValue(formValuesCopy, type, e, { ...this.props }, index);
    this.setState({
      formValues: newFormValues,
    });
  };

  handleLineSelectValue = (index, type, e) => {
    const { formValues } = this.state;
    const formValuesCopy = JSON.parse(JSON.stringify(formValues));
    const newFormValues = selectValue(formValuesCopy, type, e, { ...this.props }, index);
    this.setState({ formValues: newFormValues });
  };

  addPurchaseLine = () => {
    const { formValues } = this.state;
    const formValuesCopy = JSON.parse(JSON.stringify(formValues));
    if (formValuesCopy.lines.length < 8) {
      const newPurchaseLine = {
        inputProductUid: '',
        numberOfBins: 0,
        pricePerUnit: 0,
        buyQuantity: 0,
        sellQuantity: 0,
        expectedProductionDate: null,
        priceType: 'spot',
        formulaName: '',
        formulaMarket: 'USDA National',
        formulaDay: '',
        formulaDaysAveraged: 0,
        formulaBasis: -0.01,
        contractStartDate: '',
        contractEndDate: '',
      };
      formValuesCopy.lines.push(newPurchaseLine);
      this.setState({
        formValues: formValuesCopy,
      });
    }
  };

  removePurchaseLine = index => {
    const { formValues } = this.state;
    const formValuesCopy = JSON.parse(JSON.stringify(formValues));
    formValuesCopy.lines.splice(index, 1);
    this.setState({
      formValues: formValuesCopy,
    });
  };

  toggleLoadingState = value => {
    this.setState({
      isLoading: value,
    });
  };

  finalize = () => {
    const { formValues, singleOrderForm, copies } = this.state;
    const { units_of_measure: unitsOfMeasure, dispatch, token } = this.props;

    const formUOM = unitsOfMeasure.find(uom => uom.name === 'lbs');
    formValues.buyUnitOfMeasureId = formUOM.id;

    const error = checkErrors(formValues, this.props);
    const buyQuantityError = validateQuantity(formValues, this.props);
    const dateError = checkDateErrors(formValues);
    const fieldErrors = validateFields(formValues, ['packerPlantId', 'grinderUid', 'deliveryDate']);

    let shipmentDate = '';
    let deliveryDate = '';
    let productionDate = '';

    if (!buyQuantityError && !error && (!dateError.hasDateSequenceError || !singleOrderForm) && !fieldErrors) {
      this.setState({ error: false, dateError: false, buyQuantityError: false });
      this.toggleLoadingState(true);
      if (singleOrderForm) {
        shipmentDate = formValues.shipmentDate !== '' && formValues.shipmentDate ? formValues.shipmentDate : '';
        deliveryDate = formValues.deliveryDate !== '' && formValues.deliveryDate ? formValues.deliveryDate : '';
        productionDate = formValues.productionDate !== '' && formValues.productionDate ? formValues.productionDate : '';
      }

      formValues.deliveryDate = deliveryDate;
      formValues.shipmentDate = shipmentDate;
      formValues.productionDate = productionDate;
      // Differs if a recurring order, new endpoint
      formValues.status = 'ordered';
      this.setState({ formValues });
      if (singleOrderForm) {
        const orderToSend = prepareAmericanOrder(formValues, this.props);
        if (!orderToSend.buyer_id) {
          orderToSend.buyer_id = this.props.user.id;
        }
        dispatch(sendOrder(orderToSend, copies, token)).finally(() => {
          this.toggleLoadingState(false);
        });
      } else {
        const rules = {
          startDate: formValues.recurringDateStart,
          endDate: formValues.recurringDateEnd,
          ordersPerDayOfWeek: formValues.ordersPerDayOfWeek,
        };
        const recurringOrder = prepareRecurringOrder(formValues, this.props, Constants.PO_FORM_TYPE.AMERICA_DOMESTIC);
        dispatch(sendRecurringOrder(recurringOrder, token)).finally(() => {
          this.toggleLoadingState(false);
        });
      }
    } else {
      if (error || fieldErrors) {
        this.setState({ error: true });
      }
      if (buyQuantityError) {
        this.setState({ buyQuantityError: true });
      }
      if (dateError.hasDateSequenceError) {
        this.setState({ dateError: true });
      } else {
        this.setState({ dateError: false });
      }
      if (dateError.shipmentDateError) {
        this.setState({ shipmentDateError: true });
      }
      this.toggleLoadingState(false);
    }
  };

  reset(clearMessage) {
    this.setState({
      error: false,
      buyQuantityError: false,
      formValues: defaultFormValues,
      copies: 1,
      ...(clearMessage && { finalizeSuccessMsg: false }),
    });
  }

  calculateTotalCost() {
    let price = 0;
    let totalQuantity = 0;
    const { formValues } = this.state;

    formValues.lines.forEach(line => {
      price += line.pricePerUnit * line.buyQuantity;
      const buyQuantity = typeof line.buyQuantity === 'string' ? Number(line.buyQuantity) : line.buyQuantity;
      totalQuantity += buyQuantity;
    });

    const transportCost = totalQuantity * formValues.transportCostPerUnit;
    return isNaN(price) || isNaN(transportCost) ? 0 : price + transportCost;
  }

  render() {
    const { formValues, error, dateError, buyQuantityError, singleOrderForm, finalizeSuccessMsg, shipmentDateError } =
      this.state;

    const {
      load_sizes: loadSizes,
      units_of_measure: unitsOfMeasure,
      packer_plants: packerPlants,
      input_products: inputProducts,
      pendingLoaded,
      fastLoaded,
      latestOrders,
      endUsers,
      configs,
    } = this.props;
    const totalCost = this.calculateTotalCost();

    // Finding number of dates between contract start and contract end
    // Any day of the week marked as more than 0 should be included
    // So, we find a start. Then an end.
    let numberOfOrders = 0;
    if (!singleOrderForm && formValues.contractStartDate !== '' && formValues.contractEndDate) {
      const start = moment(formValues.contractStartDate);
      const end = moment(formValues.contractEndDate);

      formValues.ordersPerDayOfWeek.forEach(function (numberOfOrdersInDay, index) {
        if (numberOfOrdersInDay > 0) {
          const day = index;
          const current = start.clone();
          while (current.day(7 + day).isSameOrBefore(end)) {
            numberOfOrders += Number(numberOfOrdersInDay);
          }
        }
      });
    }

    return (
      <Box className="single-ingredient-page" style={{ padding: '20px', marginBottom: '20px' }}>
        <ReactTooltip />
        <VStack
          align="stretch"
          spacing="20px"
          padding="20px 24px 20px 24px"
          boxShadow="md"
          borderRadius="15px"
          backgroundColor="card.default"
          mt={'10px'}
          mb={'30px'}
        >
          <Flex alignItems="center" width="100%">
            <HStack spacing="10px">
              <RightAngleIcon />
              <Heading as="h2" fontSize="15px" data-test="purchase-order-heading">
                {singleOrderForm ? 'Single' : 'Recurring'} Order Form
              </Heading>
            </HStack>
          </Flex>

          <RadioGroup
            my="30px"
            value={singleOrderForm ? 'single' : 'recurring'}
            onChange={value => {
              if (value === 'single') {
                const newFormValues = JSON.parse(JSON.stringify(formValues));
                newFormValues.price_type = 'spot';
                this.setState({
                  singleOrderForm: true,
                  formValues: newFormValues,
                });
              } else if (value === 'recurring') {
                const newFormValues = JSON.parse(JSON.stringify(formValues));
                newFormValues.price_type = 'contract';
                this.setState({
                  singleOrderForm: false,
                  formValues: newFormValues,
                });
              }
            }}
          >
            <HStack alignItems="center" justifyContent="end">
              <Radio value="single" my={0}>
                Single
              </Radio>
              <Radio value="recurring" my={0}>
                Recurring
              </Radio>
            </HStack>
          </RadioGroup>

          <Box px="28px">
            <PurchaseOrderForm
              data-test="purchase-order-form"
              error={error}
              formValues={formValues}
              dateError={dateError}
              removePurchaseLine={this.removePurchaseLine}
              singleOrderForm={singleOrderForm}
              handleSelectValue={this.handleSelectValue}
              addPurchaseLine={this.addPurchaseLine}
              handleLineSelectValue={this.handleLineSelectValue}
              {...this.props}
            />
          </Box>
        </VStack>

        <VStack
          align="stretch"
          spacing="20px"
          padding="20px 24px 20px 24px"
          boxShadow="md"
          borderRadius="15px"
          backgroundColor="card.default"
          mt="10px"
        >
          <Flex alignItems="center" width="100%">
            <HStack spacing="10px">
              <RightAngleIcon />
              <Heading fontSize="15px">Summary</Heading>
            </HStack>
          </Flex>
          <HStack>
            <Box w="40%">
              <Table size="lg" variant="unstyled">
                <Thead>
                  <Tr>
                    <Th>Product CLs</Th>
                    <Th textAlign="right">Total Product Cost</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {formValues.lines.map((line, i) => {
                    let currentInputProduct = 'Select a product.';
                    inputProducts.forEach(ip => {
                      if (ip.uid === line.inputProductUid) {
                        currentInputProduct = ip;
                      }
                    });
                    const thisCost = Math.round(line.pricePerUnit * line.buyQuantity);
                    return (
                      <>
                        <Tr key={`purchase-order-line-${i}`}>
                          <Td>
                            {typeof currentInputProduct === 'string'
                              ? currentInputProduct
                              : commify(currentInputProduct.cl)}
                          </Td>
                          <Td textAlign="right">{commify(thisCost)}</Td>
                        </Tr>
                        <Tr py={0}>
                          <Td colSpan={2} py={0}>
                            <Divider />
                          </Td>
                        </Tr>
                      </>
                    );
                  })}
                </Tbody>
              </Table>
              <Text my={4} px={'28px'}>
                <strong>Total Cost</strong>:
                <Tooltip content="Displays the cumulative cost of the selected products." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
                &nbsp;
                {totalCost !== 0
                  ? `$${totalCost.toFixed(2)}`
                  : 'Enter a number of bins, price per unit, and transport cost per unit.'}
              </Text>
            </Box>
          </HStack>

          <HStack mt="10px">
            <Box>
              {error || buyQuantityError || (dateError && singleOrderForm) || shipmentDateError ? (
                <ErrorMessage
                  form={formValues}
                  buyQuantityError={buyQuantityError}
                  dateError={dateError}
                  shipmentDateError={shipmentDateError}
                  showShipmentDateErrorSeparately
                  packerPlants={packerPlants}
                  unitsOfMeasure={unitsOfMeasure}
                  loadSizes={loadSizes}
                />
              ) : (
                false
              )}
            </Box>
          </HStack>
        </VStack>
        <VStack alignItems="end">
          <Box mt={4}>
            {finalizeSuccessMsg ? (
              <OrderFormFinalizeMessage
                reset={this.reset}
                pendingLoaded={pendingLoaded}
                fastLoaded={fastLoaded}
                latestOrders={latestOrders}
                copies={this.state.copies}
              />
            ) : (
              false
            )}
          </Box>
          <Box style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button colorScheme="actionPrimary" onClick={this.finalize.bind(this)} isLoading={this.state.isLoading}>
              Create and Submit POs
            </Button>
          </Box>
        </VStack>
      </Box>
    );
  }
}

const PurchaseOrderUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.CHICAGO_ORDER}
        show={<PurchaseOrder {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default PurchaseOrderUnleashController;
