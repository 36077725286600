import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  IconButton,
  Select,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { IoInformationCircleOutline } from 'react-icons/io5';
import moment from 'moment';
import FileUploader from './FileUploader';
import { uploadFileToS3Request } from '../../../data-fixtures/basic';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_food_safety_quality';
import Constants from '../../../Constants';
import DatePicker from '../../core/DatePicker';
import Tooltip from './Tooltip';
import PackerSelectBox from './PackerSelectBox';

const resourceTitle = {
  beef_bundle: 'Beef Bundle',
  haccp: 'HACCP Letter',
  annual_audit_report: 'Annual Site Audit Report',
  audit_certificate: 'Audit Certificate',
  btt: 'BTT Guide / FMG Docs',
  spec: 'Specifications',
};

const AddDocument = ({ editItem, resourceType, isOpenAddPopup, setIsOpenAddPopup }) => {
  const { addNewResource, updateResource } = actions;
  const dispatch = useDispatch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    rs_title: Yup.string().max(100).required('Title is Required'),
    rs_doc_number: Yup.string().max(30).required('Document No. is Required'),
    rs_path: Yup.string().nullable().required('File is Required'),
    rs_issued_date: Yup.date().required('Issued Date is Required'),
    ...(resourceType === 'beef_bundle' && { activate_beef_bundle: Yup.string().required('Activation is Required') }),
    ...((resourceType === 'haccp' ||
      resourceType === 'annual_audit_report' ||
      resourceType === 'audit_certificate') && {
      rs_associated_id: Yup.string().required('Packer is Required'),
      rs_expired_on: Yup.date()
        .required('Expiry Date is Required')
        .when('rs_issued_date', (isseDate, schema) =>
          schema.min(isseDate ? new Date(isseDate) : new Date(), 'Expiry Date should be greater then Issued Date')
        ),
    }),
  });

  const addDocumentHandler = async formValues => {
    try {
      setIsLoading(true);
      let resource;
      let newValues = JSON.parse(JSON.stringify(formValues));

      if (formValues.rs_path instanceof File) {
        const filePath = await uploadFileToS3Request({ file: formValues.rs_path });
        newValues = { ...formValues, rs_path: filePath };
      }

      if (editItem) {
        delete newValues.id;
        resource = await api.patch(`/op-doc-resources/${formValues.id}`, newValues);
      } else {
        resource = await api.post('/op-docs-resources', newValues);
      }
      const data = await resource.json();
      if (editItem) {
        dispatch(updateResource({ ...data, type: resourceType }));
      } else {
        dispatch(addNewResource({ ...data, type: resourceType }));
      }
      setIsOpenAddPopup(false);
      toast({
        title: `Document ${editItem ? 'Updated' : 'Added'} Successfully`,
        position: 'top-right',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: error.message,
        position: 'top-right',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      rs_title: '',
      rs_doc_number: '',
      rs_path: null,
      rs_issued_date: '',
      rs_type_name: resourceType,
      rs_associated_id: '',
      rs_expired_on: '',
      activate_beef_bundle: '',
    },
    validationSchema,
    onSubmit: addDocumentHandler,
  });

  useEffect(() => {
    if (editItem) {
      const { id, rs_title, rs_doc_number, rs_path, rs_issued_date } = editItem;
      setFieldValue('id', id);
      setFieldValue('rs_title', rs_title);
      setFieldValue('rs_doc_number', rs_doc_number);
      setFieldValue('rs_path', rs_path);
      setFieldValue('rs_issued_date', rs_issued_date);

      if (resourceType === 'beef_bundle') {
        setFieldValue('activate_beef_bundle', editItem.status?.toLowerCase() === 'active' ? 'yes' : 'no');
      }

      if (resourceType === 'haccp' || resourceType === 'annual_audit_report' || resourceType === 'audit_certificate') {
        setFieldValue('rs_associated_id', editItem.rs_associated_id);
        setFieldValue('rs_expired_on', editItem.rs_expired_on);
      }
    }
  }, [editItem]);

  console.log("resourceType", resourceType)

  return (
    <Modal isOpen={isOpenAddPopup} onClose={() => setIsOpenAddPopup(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="min(900px, 80%)">
        <ModalHeader borderBottomWidth="1px" fontSize="20px">
          {editItem ? 'Edit' : 'Add'} Document
        </ModalHeader>
        <ModalCloseButton />
        <Formik>
          <form onSubmit={handleSubmit}>
            <ModalBody padding="22px">
              {(resourceType === 'haccp' ||
                resourceType === 'annual_audit_report' ||
                resourceType === 'audit_certificate') && (
                <Box flexGrow={1} marginBottom="20px">
                  <FormLabel display="flex" alignItems="center">
                    <Text as="p">
                      Packer&nbsp;
                      <Tooltip
                        content={`Choose the name of the packer associated with the ${resourceTitle[resourceType]}.`}
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </Text>
                  </FormLabel>
                  <PackerSelectBox
                    onChange={e => {
                      setFieldValue('rs_associated_id', e.currentTarget.value);
                    }}
                    value={values.rs_associated_id}
                  />
                  {errors.rs_associated_id && touched.rs_associated_id && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.rs_associated_id}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              )}
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Title&nbsp;
                    <Tooltip content={`Enter the title of the ${resourceTitle[resourceType]}`} placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input name="rs_title" value={values.rs_title} onChange={handleChange} />
                  {errors.rs_title && touched.rs_title && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.rs_title}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Document No.&nbsp;
                    <Tooltip
                      content={`Enter the document number of the ${resourceTitle[resourceType]}`}
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input name="rs_doc_number" value={values.rs_doc_number} onChange={handleChange} />
                  {errors.rs_doc_number && touched.rs_doc_number && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.rs_doc_number}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box
                  flexGrow={1}
                  flexBasis={
                    resourceType === 'haccp' ||
                    resourceType === 'annual_audit_report' ||
                    resourceType === 'audit_certificate'
                      ? '50%'
                      : '100%'
                  }
                >
                  <FormLabel>
                    Issued Date&nbsp;
                    <Tooltip
                      content={`Select the date when the ${resourceTitle[resourceType]} was issued.`}
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <DatePicker
                    id="rs_issued_date"
                    value={moment(values.rs_issued_date, Constants.DEFAULT_READ_DATE_FORMAT)}
                    onChange={e => {
                      setFieldValue('rs_issued_date', e);
                    }}
                  />
                  {errors.rs_issued_date && touched.rs_issued_date && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.rs_issued_date}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                {(resourceType === 'haccp' ||
                  resourceType === 'annual_audit_report' ||
                  resourceType === 'audit_certificate') && (
                  <Box flexGrow={1} flexBasis="50%">
                    <FormLabel>
                      Expiry Date&nbsp;
                      <Tooltip
                        content={`Select the date when the ${resourceTitle[resourceType]} will expire.`}
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <DatePicker
                      id="rs_expired_on"
                      value={moment(values.rs_expired_on, Constants.DEFAULT_READ_DATE_FORMAT)}
                      onChange={e => {
                        setFieldValue('rs_expired_on', e);
                      }}
                    />
                    {errors.rs_expired_on && touched.rs_expired_on && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{errors.rs_expired_on}</AlertDescription>
                      </Alert>
                    )}
                  </Box>
                )}
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1}>
                  <FileUploader setFieldValue={setFieldValue} fieldName="rs_path" fileObject={values.rs_path} />
                  {errors.rs_path && touched.rs_path && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.rs_path}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>
              {resourceType === 'beef_bundle' && (
                <Box flexGrow={1}>
                  <FormLabel display="flex" alignItems="center">
                    <Text as="p" marginRight="10px">
                      Would you like to activate this Beef Bundle?
                    </Text>
                    <Tooltip content="Only current year's Beef Bundle is active." placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    name="activate_beef_bundle"
                    placeholder="Select"
                    height="40px"
                    onChange={e => {
                      setFieldValue('activate_beef_bundle', e.currentTarget.value);
                    }}
                    value={values.activate_beef_bundle}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                  {errors.activate_beef_bundle && touched.activate_beef_bundle && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.activate_beef_bundle}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              )}
            </ModalBody>
            <ModalFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
              <ButtonGroup marginLeft="auto">
                <Button type="button" width="126px" height="43px" onClick={() => setIsOpenAddPopup(false)}>
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Close
                  </Text>
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  loadingText="Saving..."
                  isLoading={isLoading}
                >
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Save
                  </Text>
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};

AddDocument.propTypes = {
  editItem:
    PropTypes.shape({
      id: PropTypes.number,
      rs_title: PropTypes.string,
      rs_doc_number: PropTypes.string || PropTypes.number,
      rs_path: PropTypes.string,
      rs_issued_date: PropTypes.string,
    }) || PropTypes.null,
  resourceType: PropTypes.string.isRequired,
  isOpenAddPopup: PropTypes.bool.isRequired,
  setIsOpenAddPopup: PropTypes.func.isRequired,
};

export default AddDocument;
