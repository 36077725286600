import { Center } from '@chakra-ui/react';
import React, { Component } from 'react';
import Constants from '../../Constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class ResetPassword extends Component {
  static propTypes = {
    theme: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      newPassword: '',
    };
  }

  handlePasswordChange(e) {
    e.preventDefault();
    if (this.state.newPassword !== '') {
      this.setState({ error: false });
      const token = getParameterByName('token');
      const header = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      });
      const that = this;
      fetch(`${Constants.URL}reset-password`, {
        method: 'POST',
        headers: header,
        body: JSON.stringify({
          password: that.state.newPassword,
        }),
      })
        .then(response => response.json())
        .then(json => {
          console.log(json);
          if (json.title) {
            that.setState({ error: json.title });
          } else {
            window.location = `/?token=${token}`;
          }
        })
        .catch(error => alert(error));
    } else {
      this.setState({ error: 'Please enter a new password.' });
    }
  }

  render() {
    const { theme } = this.props;

    return (
      <div className="login-container login-page">
        <div className="login">
          <div className="title">
            <Center>
              <img className="logo" src={theme?.data?.home?.logo} alt="" />
            </Center>
            <h3 id="form-title" className="form-title font-green">
              {theme?.data?.home?.welcomeMsg}
            </h3>
          </div>
          <div className="content">
            <form className="login-form" onSubmit={this.handlePasswordChange.bind(this)}>
              <p className="autologin-text-0">Enter a new password below.</p>
              <div className="alert alert-info">
                <span>
                  Password must be at least 8 characters long, and contain at least one uppercase letter, one special
                  letter, and one number.
                </span>
              </div>
              <input
                id="password-reset-input"
                className="form-control form-control-solid placeholder-no-fix"
                type="password"
                onChange={e => this.setState({ newPassword: e.target.value })}
                value={this.state.newPassword}
                placeholder="New Password"
              />
              {this.state.error ? (
                <div id="alert-error" style={{ marginTop: '10px' }} className="alert alert-danger">
                  <span id="alert-text">{this.state.error}</span>
                </div>
              ) : (
                false
              )}
              <div className="form-actions">
                <button type="submit" value="continue">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(ResetPassword);
