import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { uploadFileToS3Request } from '../../../data-fixtures/basic';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_food_safety_quality';
import countryCodes from '../../../shared/countryCodes';
import Tooltip from '../_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddContact = ({ editItem, isOpenAddPopup, setIsOpenAddPopup }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { addNewContact, updateContact } = actions;

  const validationSchema = Yup.object().shape({
    contact_of: Yup.string().required('Contact For is a required field'),
    name: Yup.string().max(100).required('Name is a required field'),
    role: Yup.string().max(100).required('Role is a required field'),
    contact_type: Yup.string().required('Contact Type is a required field'),
    country_code: Yup.string().required('Country Code is a required field'),
    mobile: Yup.string()
      .min(7, 'Phone number is not valid')
      .max(15, 'Phone number is not valid')
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Number is a required field'),
    email: Yup.string().email('Please enter a valid email address.').max(100).required('Email is a required field'),
    associated_to: Yup.string()
      .nullable()
      .when('contact_of', (contact_of, schema) =>
        contact_of === 'Grinder' || contact_of === 'Sponsor'
          ? schema.required(`${contact_of} is a required field`)
          : schema.notRequired()
      ),
  });

  const addContactHandler = async formValues => {
    try {
      setIsLoading(true);
      let resource;
      let newValues = JSON.parse(JSON.stringify(formValues));

      if (formValues.rs_path instanceof File) {
        const filePath = await uploadFileToS3Request({ file: formValues.rs_path });
        newValues = { ...formValues, rs_path: filePath };
      }

      if (editItem) {
        delete newValues.id;
        resource = await api.patch(`/op-importantcontact/${formValues.id}`, newValues);
      } else {
        resource = await api.post('/op-importantcontacts', newValues);
      }
      const data = await resource.json();
      if (editItem) {
        dispatch(updateContact(data));
      } else {
        dispatch(addNewContact(data));
      }
      setIsOpenAddPopup(false);
      toast({
        title: `Contact ${editItem ? 'Updated' : 'Added'} Successfully`,
        position: 'top-right',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: error.message,
        position: 'top-right',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      contact_of: editItem ? editItem.contact_of : '',
      name: editItem ? editItem.name : '',
      role: editItem ? editItem.role : '',
      contact_type: editItem ? editItem.contact_type : '',
      country_code: editItem ? editItem.country_code : '',
      mobile: editItem ? editItem.mobile : '',
      email: editItem ? editItem.email : '',
      associated_to: editItem ? editItem.associated_to || '' : '',
    },
    validationSchema,
    onSubmit: addContactHandler,
  });

  useEffect(() => {
    if (editItem) {
      setFieldValue('id', editItem.id);
      setFieldValue('contact_of', editItem.contact_of);
      setFieldValue('associated_to', editItem.associated_to);
      setFieldValue('name', editItem.name);
      setFieldValue('role', editItem.role);
      setFieldValue('contact_type', editItem.contact_type);
      setFieldValue('country_code', editItem.country_code);
      setFieldValue('mobile', editItem.mobile);
      setFieldValue('email', editItem.email);
    }
  }, [editItem]);

  return (
    <Modal isOpen={isOpenAddPopup} onClose={() => setIsOpenAddPopup(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="min(900px, 80%)">
        <ModalHeader borderBottomWidth="1px" fontSize="20px">
          {editItem ? 'Edit' : 'Add'} Contact
        </ModalHeader>
        <ModalCloseButton />
        <Formik>
          <form onSubmit={handleSubmit}>
            <ModalBody padding="22px">
              <Box flexGrow={1} marginBottom="20px">
                <FormLabel display="flex" alignItems="center">
                  <Text as="p">
                    Contact For&nbsp;
                    <Tooltip content="Select the appropriate contact group" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </Text>
                </FormLabel>
                <Select
                  name="contact_of"
                  placeholder="Select"
                  height="40px"
                  onChange={e => {
                    setFieldValue('contact_of', e.currentTarget.value);
                  }}
                  value={values.contact_of}
                >
                  <option value="FMG">FMG</option>
                  <option value="Grinder">Grinder</option>
                  <option value="Sponsor">Sponsor</option>
                </Select>
                {errors.contact_of && touched.contact_of && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.contact_of}</AlertDescription>
                  </Alert>
                )}
              </Box>

              {values.contact_of === 'Grinder' && (
                <Box flexGrow={1} marginBottom="20px">
                  <FormLabel display="flex" alignItems="center">
                    <Text as="p">
                      Grinder&nbsp;
                      <Tooltip content="Select the Grinder" placement="right">
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </Text>
                  </FormLabel>
                  <Select
                    name="associated_to"
                    placeholder="Select"
                    height="40px"
                    onChange={e => {
                      setFieldValue('associated_to', e.currentTarget.value);
                    }}
                    value={values.associated_to}
                  >
                    <option value="Tyson">Tyson</option>
                    <option value="TurOSI">TurOSI</option>
                    <option value="ANZCO">ANZCO</option>
                  </Select>
                  {errors.associated_to && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.associated_to}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              )}

              {values.contact_of === 'Sponsor' && (
                <Box flexGrow={1} marginBottom="20px">
                  <FormLabel display="flex" alignItems="center">
                    <Text as="p">Sponsor</Text>
                  </FormLabel>
                  <Select
                    name="associated_to"
                    placeholder="Select"
                    height="40px"
                    onChange={e => {
                      setFieldValue('associated_to', e.currentTarget.value);
                    }}
                    value={values.associated_to}
                  >
                    <option value="Tyson">Tyson</option>
                    <option value="TurOSI">TurOSI</option>
                    <option value="ANZCO">ANZCO</option>
                  </Select>
                  {errors.associated_to && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.associated_to}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              )}

              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Full Name&nbsp;
                    <Tooltip content="Enter the full name of the contact person" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input name="name" value={values.name} onChange={handleChange} />
                  {errors.name && touched.name && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.name}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Role&nbsp;
                    <Tooltip content="Specify the role of the contact person" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input name="role" value={values.role} onChange={handleChange} />
                  {errors.role && touched.role && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.role}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Contact Type&nbsp;
                    <Tooltip content="Choose the type of contact (e.g., Primary, Secondary)" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    name="contact_type"
                    placeholder="Select"
                    height="40px"
                    onChange={e => {
                      setFieldValue('contact_type', e.currentTarget.value);
                    }}
                    value={values.contact_type}
                  >
                    <option value="Primary Contact">Primary Contact</option>
                    <option value="Secondary Contact">Secondary Contact</option>
                  </Select>
                  {errors.contact_type && touched.contact_type && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.contact_type}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Country Code&nbsp;
                    <Tooltip content="Select the country code associated with the contact number" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    name="country_code"
                    placeholder="Select"
                    height="40px"
                    onChange={e => {
                      setFieldValue('country_code', e.currentTarget.value);
                    }}
                    value={values.country_code}
                  >
                    {countryCodes.map(country => (
                      <option value={country.dial_code} key={country.name}>
                        {country.name} ({country.dial_code})
                      </option>
                    ))}
                  </Select>
                  {errors.country_code && touched.country_code && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.country_code}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Contact Number&nbsp;
                    <Tooltip
                      content="Enter the contact person's phone number, including the area code"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input name="mobile" value={values.mobile} onChange={handleChange} />
                  {errors.mobile && touched.mobile && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.mobile}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <FormLabel>
                    Email Address&nbsp;
                    <Tooltip content="Provide the contact person's email address" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input name="email" value={values.email} onChange={handleChange} />
                  {errors.email && touched.email && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.email}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
              <ButtonGroup marginLeft="auto">
                <Button type="button" width="126px" height="43px" onClick={() => setIsOpenAddPopup(false)}>
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Close
                  </Text>
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  loadingText="Saving..."
                  isLoading={isLoading}
                >
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Save
                  </Text>
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};

AddContact.propTypes = {
  editItem: PropTypes.shape({}) || PropTypes.null,
  isOpenAddPopup: PropTypes.bool.isRequired,
  setIsOpenAddPopup: PropTypes.func.isRequired,
};

export default AddContact;
