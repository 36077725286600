import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import { cloneDeep } from 'lodash';
import SelectField from '../../../basic/SelectField';

const PriceAdjustmentTableTd = ({ children, ...rest }) => {
  return (
    <Td paddingRight="57px" paddingLeft={0} paddingTop="8px" {...rest}>
      {children}
    </Td>
  );
};

PriceAdjustmentTableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

const PriceAdjustment = ({ line, isInEditMode, onChange }) => {
  const [productLines, setProductLines] = useState(cloneDeep(line));
  const [state, setState] = useState({
    deletedPriceAdjustmentIds: [],
    isExpanded: false,
  });

  const columns = [
    {
      title: 'Value',
      dataIndex: 'value',
      isRequired: true,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      isRequired: true,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  const onLinePriceAdjustmentsChange = (idx, field, value) => {
    setProductLines(prevProdLines => {
      const productLineClone = cloneDeep(prevProdLines);
      const linePriceAdjustment = productLineClone.priceAdjustments[idx];
      linePriceAdjustment[field] = value;
      linePriceAdjustment.purchaseOrderLineId = productLineClone.id;

      return productLineClone;
    });
  };
  const handleAdd = count => {
    const productLineClone = cloneDeep(productLines);
    // increment
    if (count > productLineClone.priceAdjustments.length) {
      // length count starts from 0
      productLineClone.priceAdjustments.push({
        value: '',
        reason: null,
        notes: '',
      });
    }
    // decrement
    else {
      productLineClone.priceAdjustments.pop();
    }
    setProductLines(productLineClone);
  };

  const handleDelete = (linePriceAdjustmentId, idx) => {
    setState(prevState => ({
      ...prevState,
      deletedPriceAdjustmentIds: [...prevState.deletedPriceAdjustmentIds, idx],
    }));

    const productLineClone = cloneDeep(productLines);
    if (linePriceAdjustmentId) {
      productLineClone.priceAdjustments.splice(
        productLineClone.priceAdjustments.findIndex(i => i.id === linePriceAdjustmentId),
        1
      );
      const deletedPriceAdjustmentIds = [...state.deletedPriceAdjustmentIds];
      // save the removed ids and make a request to api on Save
      deletedPriceAdjustmentIds.push(linePriceAdjustmentId);
      setState(prevState => ({
        ...prevState,
        deletedPriceAdjustmentIds,
      }));
    } else {
      productLineClone.priceAdjustments.splice(idx, 1);
    }
    setProductLines(productLineClone);
  };

  // -------------------------
  //   Lifecycle Hook
  // -------------------------
  useEffect(() => {
    onChange(productLines.idx, productLines.id, productLines.priceAdjustments, state.deletedPriceAdjustmentIds);

    return () => {
      setState(prevState => ({
        ...prevState,
        deletedPriceAdjustmentIds: [],
      }));
    };
  }, [productLines]);

  return (
    <Box>
      <HStack color="accent.one.default">
        <Text color="inherit" fontWeight="bold">
          Price Adjustment
        </Text>
        <IconButton
          variant="unstyled"
          minWidth="18px"
          height="fit-content"
          padding="0px"
          fontSize="24px"
          icon={state.isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          onClick={() => setState({ ...state, isExpanded: !state.isExpanded })}
        />
      </HStack>

      {state.isExpanded ? (
        <Table variant="unstyled">
          <Thead>
            <Tr>
              {columns.map(column => (
                <Th
                  key={column.dataIndex}
                  paddingLeft={0}
                  paddingBottom={0}
                  textTransform="capitalize"
                  fontSize="14px"
                  fontWeight="500"
                  fontFamily="var(--chakra-fonts-body)"
                >
                  {column.title} {column.isRequired && isInEditMode ? ' *' : ''}
                </Th>
              ))}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {productLines.priceAdjustments &&
              productLines.priceAdjustments.map((priceAdjustment, idx) => (
                <Tr key={idx}>
                  <PriceAdjustmentTableTd>
                    <Input
                      name="price-adjustment-value"
                      className="form-control"
                      type="number"
                      value={priceAdjustment.value || ''}
                      isDisabled={!isInEditMode}
                      placeholder="Value"
                      onChange={e => onLinePriceAdjustmentsChange(idx, 'value', e.target.value)}
                      backgroundColor={!isInEditMode ? 'inherit' : 'white'}
                    />
                  </PriceAdjustmentTableTd>
                  <PriceAdjustmentTableTd>
                    <Box width="360px">
                      <SelectField
                        {...{
                          isDisabled: !isInEditMode,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          name: 'select-reason',
                          defaultValue: priceAdjustment.reason,
                          onChange: e => onLinePriceAdjustmentsChange(idx, 'reason', e.value),
                          options: [
                            { value: 'price_error', label: 'Price Error' },
                            { value: 'hedge', label: 'Hedge' },
                            { value: 'claim', label: 'Claim' },
                            { value: 'flagship_farmer_recoup', label: 'Flagship Farmer Recoup' },
                          ],
                        }}
                      />
                    </Box>
                  </PriceAdjustmentTableTd>
                  <PriceAdjustmentTableTd>
                    <Input
                      minW="360px"
                      name="price-adjustment-notes"
                      className="form-control"
                      value={priceAdjustment.notes || ''}
                      isDisabled={!isInEditMode}
                      placeholder="Add Notes"
                      onChange={e => onLinePriceAdjustmentsChange(idx, 'notes', e.target.value)}
                      backgroundColor={!isInEditMode ? 'inherit' : 'white'}
                    />
                  </PriceAdjustmentTableTd>
                  <PriceAdjustmentTableTd paddingRight={0}>
                    <ButtonGroup gap="4" alignItems="center" justifyContent="flex-end" width="100%">
                      <IconButton
                        variant="ghost"
                        icon={<TrashOutlineIconSVG />}
                        isDisabled={!isInEditMode}
                        onClick={() => {
                          handleDelete(priceAdjustment.id, idx);
                        }}
                      />
                      <IconButton
                        onClick={() => handleAdd(productLines.priceAdjustments.length + 1)}
                        isDisabled={!isInEditMode}
                        icon={
                          <i
                            className="la la-plus international-order-line__icon--orange"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        }
                        colorScheme="actionSecondary"
                        height="25px"
                        minWidth="25px"
                        borderRadius="32px"
                      />
                    </ButtonGroup>
                  </PriceAdjustmentTableTd>
                </Tr>
              ))}
            {productLines.priceAdjustments && productLines.priceAdjustments.length < 1 ? (
              <Tr>
                <PriceAdjustmentTableTd colSpan={4} paddingRight={0}>
                  <Flex>
                    <IconButton
                      marginLeft="auto"
                      onClick={() => handleAdd(productLines.priceAdjustments.length + 1)}
                      isDisabled={!isInEditMode}
                      icon={
                        <i
                          className="la la-plus international-order-line__icon--orange"
                          style={{
                            fontSize: '16px',
                          }}
                        />
                      }
                      colorScheme="actionSecondary"
                      height="25px"
                      minWidth="25px"
                      borderRadius="32px"
                    />
                  </Flex>
                </PriceAdjustmentTableTd>
              </Tr>
            ) : (
              ''
            )}
          </Tbody>
        </Table>
      ) : (
        ''
      )}
    </Box>
  );
};

PriceAdjustment.propTypes = {
  onChange: PropTypes.func,
  isInEditMode: PropTypes.bool,
  line: PropTypes.instanceOf(PropTypes.object),
};

export default PriceAdjustment;
